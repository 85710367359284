<template>
  <tr>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
      <div class="flex items-center">
        <div class="h-10 w-10 flex-shrink-0">
          <img
            class="h-10 w-10 rounded-full"
            :src="user.profile.picture"
            v-if="user.profile"
            alt=""
          />
          <UserCircleIcon
            v-else
            class="h-10 w-10 rounded-full mr-1"
            aria-hidden="true"
          />
        </div>
        <div class="ml-4">
          <div class="font-medium text-gray-900">
            {{ user.profile?.email || user }}
          </div>
          <div class="text-gray-500">{{ user.profile?.name }}</div>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <span
        class="inline-flex rounded-full bg-lime-100 px-2 text-xs font-semibold leading-5 text-lime-800"
        >Active</span
      >
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      {{ user.role === "owner" ? "Owner" : user.role ? "" : "Invited" }}
      <a
        v-if="!user.role"
        class="underline hover:text-blue-400"
        href="#"
        @click.prevent="resendInvite()"
        >Resend</a
      >
    </td>
    <td
      class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
    >
      <button
        v-if="user.role !== 'owner' && deleteEnabled"
        @click.prevent="deleteMember()"
        type="button"
        class="inline-flex items-center gap-x-1.5 rounded-md bg-lime-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
      >
        <TrashIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Remove
      </button>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from "vue";
import { TrashIcon, UserCircleIcon } from "@heroicons/vue/24/solid";

export default defineComponent({
  components: { TrashIcon, UserCircleIcon },
  props: {
    user: {
      type: [Object, String],
      required: true,
    },
    deleteEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["delete-member", "resend-invite"],
  data() {
    return {};
  },
  created() {},
  methods: {
    deleteMember() {
      this.$emit("delete-member", this.user);
    },
    resendInvite() {
      this.$emit("resend-invite");
    },
  },
});
</script>
