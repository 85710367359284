<template>
  <TransitionRoot as="template" :show="isOpen">
    <DialogVue
      as="div"
      class="fixed inset-0 overflow-hidden"
      @close="isOpen = false"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="px-4 py-6 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          {{ isNewProject ? "New project" : "Edit Project" }}
                        </DialogTitle>
                        <p class="text-sm text-gray-500" v-if="isNewProject">
                          Get started by filling in the information below to
                          create your new project.
                        </p>
                        <p class="text-sm text-gray-500" v-else>
                          Change the project details
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button
                          type="button"
                          class="text-gray-400 hover:text-gray-500"
                          @click="isOpen = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200"
                  >
                    <!-- Project name -->
                    <div
                      class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="project-name"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Project name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          v-if="isNewProject"
                          v-model.trim="newProjectForm.name"
                          type="text"
                          name="project-name"
                          id="project-name"
                          class="block w-full shadow-sm sm:text-sm focus:ring-lime-500 focus:border-lime-500 border-gray-300 rounded-md"
                        />
                        <input
                          v-else
                          v-model.trim="updateProjectForm.name"
                          type="text"
                          name="project-name"
                          id="project-name"
                          class="block w-full shadow-sm sm:text-sm focus:ring-lime-500 focus:border-lime-500 border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="px-4 sm:px-6 sm:py-5">
                      <div class="flex justify-between">
                        <div class="flex items-center">
                          <font-awesome-icon
                            class="w-5 h-5 mt-0.5 text-gray-400 mr-2"
                            icon="fa-brands fa-jira"
                          />
                          <label
                            class="block text-sm font-medium text-gray-900"
                          >
                            Jira Cloud
                          </label>
                        </div>
                        <Spinner v-if="loadingAtlassian" aria-hidden="true" />
                        <SwitchVue
                          v-else
                          :checked="atlassianAuthorized"
                          @click="atlassianAuthClickedByUser"
                          :class="[
                            atlassianAuthorized ? 'bg-lime-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-600 focus:ring-offset-2',
                          ]"
                        >
                          <span
                            aria-hidden="true"
                            :class="[
                              atlassianAuthorized
                                ? 'translate-x-5'
                                : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            ]"
                          />
                        </SwitchVue>
                      </div>
                      <div class="text-sm text-gray-600 ml-7 mt-2">
                        Automatically create Jira issues when a new Dash is
                        published to this project
                      </div>
                      <div
                        v-if="atlassianAuthorized"
                        class="float-right text-sm text-gray-600 mt-6"
                      >
                        <Selector
                          :options="[
                            { name: 'Do not create', id: null },
                            ...jiraProjects.map((p) => ({
                              name: p.name,
                              id: p.id,
                            })),
                          ]"
                          :selected-id="
                            isNewProject
                              ? newProjectForm.syncedJiraProject
                              : updateProjectForm.syncedJiraProject
                          "
                          @option-selected="jiraProjectSelected"
                          label="Project"
                          class="flex items-center justify-end"
                        />
                        <Selector
                          v-if="
                            isNewProject
                              ? !!newProjectForm.syncedJiraProject
                              : !!updateProjectForm.syncedJiraProject
                          "
                          :options="
                            (
                              jiraProjects.find(
                                (p) =>
                                  p.id ===
                                  (isNewProject
                                    ? newProjectForm.syncedJiraProject
                                    : updateProjectForm.syncedJiraProject)
                              )?.issueTypes || []
                            ).filter((it) => !it.subtask)
                          "
                          :selected-id="
                            isNewProject
                              ? newProjectForm.jiraIssueType
                              : updateProjectForm.jiraIssueType
                          "
                          @option-selected="issueTypeSelected"
                          label="Issue Type"
                          class="flex items-center justify-end mt-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6"
                >
                  <div class="space-x-3 flex justify-start">
                    <button
                      v-if="isNewProject"
                      @click.prevent="createProject"
                      type="submit"
                      class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    >
                      Create
                    </button>
                    <button
                      v-else
                      @click.prevent="updateProject"
                      type="submit"
                      class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    >
                      Submit
                    </button>
                    <button
                      @click="isOpen = false"
                      type="button"
                      class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </DialogVue>
  </TransitionRoot>
</template>

<script>
import {
  Dialog as DialogVue,
  DialogOverlay,
  DialogTitle,
  Switch as SwitchVue,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
// import { mapState } from "vuex";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { datadogLogs as log } from "@datadog/browser-logs";
import Spinner from "./Spinner.vue";
import Selector from "./Selector.vue";
import EnsureCloud from "../../helpers/ensure-cloud";

export default {
  components: {
    DialogVue,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    SwitchVue,
    Spinner,
    Selector,
  },
  props: ["type", "projectId"],
  emits: ["new-project", "update-project"],
  data() {
    return {
      isOpen: false,
      newProjectForm: {
        name: "",
        team: this.$store.state.user.team.id,
      },
      updateProjectForm: {},
      atlassianAuthorized: false,
      jiraWindowClosedAutomatically: false,
      jiraIntervalId: null,
      jiraProjects: [],
      messageListener: null,
      loadingAtlassian: false,
    };
  },
  computed: {
    isNewProject() {
      return this.type === "newProject";
    },
  },
  watch: {
    $route() {
      this.fetchProject();
    },
  },
  async mounted() {
    await EnsureCloud();

    this.messageListener = async (event) => {
      if (event.data.type === "atlassian-callback") {
        this.jiraWindowClosedAutomatically = true;

        const { authCode } = event.data;

        if (authCode) {
          const result = await Cloud.atlassianSetToken({
            authCode,
            projectId: this.projectId,
          })
            .headers({
              Authorization: `Bearer ${this.$store.state.token}`,
            })
            .tolerate((err) => {
              log.logger.error(err);
            });

          if (result) {
            const { updatedUser, jiraProjects } = result;
            this.$store.commit("setUser", updatedUser);
            this.jiraProjects = jiraProjects;
            this.atlassianAuthorized = true;
            this.loadingAtlassian = false;
          }
        } else {
          this.atlassianAuthorized = false;
          this.loadingAtlassian = false;
        }
      }
    };

    window.addEventListener("message", this.messageListener);

    if (!this.isNewProject) this.fetchProject();

    const authorized = !!this.$store.state.user.team.atlassian;

    // Only set authorized once jira projects are fetched
    // Because once atlassianAuthorized is true, then we mount the selectors
    if (authorized) {
      // While we load the jira projects, we show a spinner
      this.loadingAtlassian = true;
      await this.fetchJiraProjects();
      this.atlassianAuthorized = authorized;
      this.loadingAtlassian = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener("message", this.messageListener);
  },

  methods: {
    fetchProject() {
      const project = this.$store.getters.getProjectById(this.projectId);
      this.updateProjectForm = {
        ...project,
        syncedJiraProject: project?.jira?.project,
        jiraIssueType: project?.jira?.issueType,
      };
    },
    open() {
      this.isOpen = true;
    },
    async createProject() {
      // Validate name is not empty
      if (!this.newProjectForm.name) {
        this.$toast.error("Please provide a valid project name.");
        return;
      }

      const newProject = await Cloud.projectCreate({
        ...this.newProjectForm,
        jira: this.newProjectForm.syncedJiraProject
          ? {
              project: this.newProjectForm.syncedJiraProject,
              issueType: this.newProjectForm.jiraIssueType,
            }
          : null,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
          this.$toast.open({
            message: "There was a problem creating the project",
            type: "error",
          });
        });

      if (newProject.name) {
        this.$toast.success("Project Created Successfully");
        this.$emit("new-project", newProject);
        this.isOpen = false;
      }
    },
    async updateProject() {
      if (!this.updateProjectForm.name) {
        this.$toast.error("Please provide a valid project name.");
        return;
      }

      const projectUpdatePayload = _.chain(this.updateProjectForm)
        .pick(["name", "id"])
        .assign({
          team: this.$store.state.user.team.id,
          jira: this.updateProjectForm.syncedJiraProject
            ? {
                project: this.updateProjectForm.syncedJiraProject,
                issueType: this.updateProjectForm.jiraIssueType,
              }
            : null,
        })
        .value();

      const updatedProject = await Cloud.projectUpdate(projectUpdatePayload)
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
          this.$toast.open({
            message: "There was a problem updating the project",
            type: "error",
          });
          this.isOpen = false;
        });

      if (updatedProject.name) {
        this.$toast.success("Project Updated Successfully");
        this.$emit("update-project", updatedProject);
        this.isOpen = false;
      }
    },
    async jiraAuthenticate() {
      const jiraWindow = window.open(
        `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${
          import.meta.env.VITE_ATLASSIAN_CLIENT_ID
        }&scope=read%3Ajira-work%20write%3Ajira-work%20offline_access&redirect_uri=${
          import.meta.env.VITE_APP_ENDPOINT
        }/atlassian-callback&state=${
          import.meta.env.VITE_ATLASSIAN_USER_BOUND_VALUE
        }&response_type=code&prompt=consent`,
        "Jira Authentication",
        "width=400,height=700"
      );

      if (this.jiraIntervalId) {
        clearInterval(this.jiraIntervalId);
      }

      this.jiraIntervalId = setInterval(() => {
        if (jiraWindow.closed) {
          clearInterval(this.jiraIntervalId);

          // This means the user closed the window manually without authorizing
          if (!this.jiraWindowClosedAutomatically) {
            this.loadingAtlassian = false;
            this.atlassianAuthorized = false;
          }
        }
      }, 100);
    },
    async atlassianAuthClickedByUser() {
      if (!this.atlassianAuthorized) {
        // Previously false, so changed to true

        this.loadingAtlassian = true;
        this.jiraWindowClosedAutomatically = false;
        this.jiraAuthenticate();
      } else {
        this.atlassianAuthorized = false;

        const updatedUser = await Cloud.atlassianClearToken({
          projectId: this.projectId,
        })
          .headers({
            Authorization: `Bearer ${this.$store.state.token}`,
          })
          .tolerate((err) => {
            log.logger.error(err);
            this.atlassianAuthorized = true;
          });

        if (updatedUser) {
          this.$store.commit("setUser", updatedUser);
          this.jiraProjects = [];

          if (this.isNewProject) {
            this.newProjectForm.syncedJiraProject = null;
            this.newProjectForm.jiraIssueType = null;
          } else {
            this.updateProjectForm.syncedJiraProject = null;
            this.updateProjectForm.jiraIssueType = null;
          }
        }
      }
    },
    async fetchJiraProjects() {
      const jiraProjects = await Cloud.jiraProjectsGet({
        projectId: this.projectId,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (jiraProjects) {
        this.jiraProjects = jiraProjects;
      }
    },
    jiraProjectSelected(id) {
      if (this.isNewProject) {
        this.newProjectForm.syncedJiraProject = id;
      } else {
        this.updateProjectForm.syncedJiraProject = id;
      }
    },
    issueTypeSelected(id) {
      if (this.isNewProject) {
        this.newProjectForm.jiraIssueType = id;
      } else {
        this.updateProjectForm.jiraIssueType = id;
      }
    },
  },
};
</script>
