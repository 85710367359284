<template>
  <form>
    <div class="px-4 sm:px-6 lg:px-8 rounded shadow-lg bg-white p-6 mt-6">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Usage</h1>
          <p class="mt-2 text-sm text-gray-700">
            Monthly usage statistics for sandbox and hosted environments.
          </p>
        </div>
      </div>
      <Spinner v-if="isLoading" />
      <table v-else class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Month
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Sandbox Runner Minutes
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Hosted Runner Minutes
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="(data, month) in usageData" :key="month">
            <td
              class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
            >
              {{ month }}
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              {{ data.sandbox }}
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              {{ data.hosted }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-1"></div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { datadogLogs as log } from "@datadog/browser-logs";
import EnsureCloud from "../helpers/ensure-cloud";
import Spinner from "../components/Library/Spinner.vue";

export default defineComponent({
  components: { Spinner },
  data() {
    return {
      isLoading: true,
      usageData: {}, // Store usage data here
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async fetchUsageData() {
      try {
        this.usageData = await Cloud.usageGet()
          .headers({
            Authorization: `Bearer ${this.$store.state.token}`,
          })
          .tolerate((err) => {
            this.isLoading = false;
            log.logger.error(err);
            this.$toast.error("Failed to fetch usage data");
          });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        log.logger.error(error);
        this.$toast.error("Failed to fetch usage data");
      }
    },
  },
  mounted() {
    EnsureCloud();
    this.fetchUsageData();
  },
});
</script>
