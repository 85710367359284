<template>
  <div class="flex h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-24 w-auto"
        src="/images/logo-on-light.png"
        alt="Dashcam Icon"
      />
    </div>

    <div class="m-auto">
      <Spinner />
    </div>
  </div>

  <TransitionRoot as="template" :show="ready">
    <DialogVue as="div" class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-lime-100"
                >
                  <CheckIcon class="h-6 w-6 text-lime-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900"
                    >You're authenticated!</DialogTitle
                  >
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Your browser will prompt you to launch the Dashcam desktop
                      app. Click <strong>Open</strong> to log into the desktop
                      app.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
              >
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-lime-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 sm:col-start-2"
                  @click="launchDesktopApp()"
                >
                  Launch App
                </button>
                <a
                  target="_blank"
                  href="https://docs.dashcam.io"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  ref="cancelButtonRef"
                  >Read the Docs</a
                >
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </DialogVue>
  </TransitionRoot>
</template>

<script>
import {
  Dialog as DialogVue,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";
import { datadogLogs as log } from "@datadog/browser-logs";
import EnsureCloud from "../helpers/ensure-cloud";
import Spinner from "../components/Library/Spinner.vue";

export default {
  components: {
    DialogVue,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    Spinner,
  },
  inject: ["auth"],
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    loginUrl() {
      return window.location.href.replace(
        /http.*auth0-callback/,
        "dashcam://login"
      );
    },
  },
  async mounted() {
    EnsureCloud();

    window.analytics?.page();

    const token = await this.auth.getTokenSilently();
    this.$store.commit("setToken", token);

    const user = await Cloud.whoami()
      .headers({
        Authorization: `Bearer ${this.$store.state.token}`,
      })
      .tolerate((err) => {
        console.error(err);
      });

    if (user) {
      log.logger.info("Logged In Manually");

      window.analytics?.identify(user.id, {
        email: user.profile.email,
        id: user.id,
      });

      window.analytics?.track("Desktop Auth Callback", {
        userId: user?.id,
      });

      window.analytics?.track("Signup", {
        branch: import.meta.env.BRANCH,
        code: this.$route.query.betacode,
      });

      rdt("track", "SignUp");

      gtag("event", "conversion", {
        send_to: "AW-749090699/yqV1CK6J8oAYEIvvmOUC",
      });

      qp("track", "CompleteRegistration");
    } else {
      log.logger.info("Not Logged In");
    }

    this.ready = true;

    setTimeout(() => {
      window.location = this.loginUrl;
    }, 1500);
  },
  methods: {
    launchDesktopApp() {
      window.location = this.loginUrl;
    },
  },
};
</script>
