<template>
  <div class="rounded shadow-lg bg-white">
    <form class="p-6 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">
              Team Information
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              This information will be displayed privately.
            </p>
          </div>

          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Team Name</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <input
                    placeholder="Team Name"
                    v-model="teamName"
                    type="text"
                    name="team-name"
                    id="team-name"
                    class="focus:ring-lime-500 focus:border-lime-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500"
                    :class="[
                      teamNameRequired ? 'border-red-500' : '',
                      user.role !== 'owner' ? 'cursor-not-allowed' : '',
                    ]"
                    :readonly="user.role !== 'owner'"
                  />
                  <div
                    v-if="teamNameRequired"
                    class="text-red-500 text-xs italic pt-2"
                  >
                    Team Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="user.role === 'owner' && user.team.apiKey"
            class="space-y-6 sm:space-y-5"
          >
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >API Key</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md relative">
                  <div
                    v-if="user.team.plan === 'free'"
                    class="text-sm text-gray-500"
                  >
                    <strong>API Key is only available for Pro users.</strong>
                    Subscribe to get an API Key.
                  </div>
                  <div v-else>
                    <input
                      :disabled="true"
                      :value="user.team.apiKey"
                      type="text"
                      name="api-key"
                      id="api-key"
                      class="focus:ring-lime-500 focus:border-lime-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500"
                      :readonly="true"
                    />
                    <ArrowPathIcon
                      @click="refreshApiKey"
                      class="absolute right-1 top-1 w-8 opacity-80 active:text-lime-600 hover:text-lime-400 cursor-pointer text-lime-700"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="subscription"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Current Plan</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0 flex-1">
                <div class="flex mt-1 space-between">
                  <div
                    :class="`text-sm font-medium ${subscriptionColor} self-center flex-1`"
                  >
                    {{ subscriptionText }}
                  </div>
                  <Spinner v-if="stripeIsLoading"></Spinner>
                  <button
                    v-if="!stripeIsLoading && user.role === 'owner'"
                    @click="
                      user.team.plan === 'free'
                        ? subscribe()
                        : manageSubscription()
                    "
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                  >
                    {{
                      user.team.plan === "free"
                        ? "Get TestDriver Pro"
                        : "Usage & Billing"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="user.role === 'owner'" class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Private Replays</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <SwitchGroup as="div" class="flex items-center">
                    <SwitchVue
                      :disabled="user.role !== 'owner'"
                      v-model="replayIsTeamOnly"
                      :class="[
                        replayIsTeamOnly ? 'bg-lime-600' : 'bg-gray-200',
                        user.role !== 'owner'
                          ? 'cursor-not-allowed opacity-50'
                          : '',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-600 focus:ring-offset-2',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          replayIsTeamOnly ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        ]"
                      />
                    </SwitchVue>
                    <SwitchLabel as="span" class="ml-3 text-sm">
                      <span
                        class="font-medium text-gray-900"
                        v-if="replayIsTeamOnly"
                        >Replays are private by default</span
                      >
                      <span class="font-medium text-gray-900" v-else
                        >Replays are published with unlisted links by
                        default</span
                      >
                    </SwitchLabel>
                  </SwitchGroup>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="user.team.plan !== 'free' && user.team.autoJoinPossible"
            class="space-y-6 sm:space-y-5"
          >
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Auto Join</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <SwitchGroup as="div" class="flex items-center">
                    <SwitchVue
                      :disabled="user.role !== 'owner'"
                      v-model="autoJoin"
                      :class="[
                        autoJoin ? 'bg-lime-600' : 'bg-gray-200',
                        user.role !== 'owner'
                          ? 'cursor-not-allowed opacity-50'
                          : '',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-600 focus:ring-offset-2',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          autoJoin ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        ]"
                      />
                    </SwitchVue>
                    <SwitchLabel as="span" class="ml-3 text-sm">
                      <span class="font-medium text-gray-900"
                        >Add anyone with
                        <strong>@{{ user.profile.email.split("@")[1] }}</strong>
                        email to this team</span
                      >
                    </SwitchLabel>
                  </SwitchGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      class="bg-gray-50 rounded-b px-4 py-3 text-right sm:px-6"
      v-if="user.role === 'owner'"
    >
      <button
        :disabled="user.role !== 'owner'"
        @click.prevent="teamProceed()"
        type="submit"
        class="inline-flex justify-center rounded-md bg-lime-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500"
      >
        Update
      </button>
    </div>
  </div>
  <div class="rounded shadow-lg bg-white mt-6">
    <form class="p-6 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">
              Add New Members
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Invite new members to your team.
            </p>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Invite Link</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <div class="flex mt-1">
                    <input
                      readonly
                      type="text"
                      name="invite-link"
                      id="invite-link"
                      :value="inviteLink"
                      class="focus:ring-lime-500 focus:border-lime-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500 mr-4"
                    />
                    <button
                      @click="copyInviteLink"
                      type="button"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Email Invite</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <div class="flex mt-2">
                    <input
                      placeholder="Enter the email address"
                      required
                      v-model="inviteEmail"
                      type="email"
                      name="invite-email"
                      id="invite-email"
                      :class="{ 'border-red-500': inviteEmailError }"
                      class="focus:ring-lime-500 focus:border-lime-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500 mr-4"
                    />
                    <button
                      @click="inviteMember(inviteEmail)"
                      type="button"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    >
                      Invite
                    </button>
                  </div>
                  <p
                    v-if="inviteEmailError"
                    class="text-red-500 text-xs italic pt-2"
                  >
                    {{ inviteEmailError }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <form>
    <div class="px-4 sm:px-6 lg:px-8 rounded shadow-lg bg-white p-6 mt-6">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            Team Members
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            All members can view all projects and clips.
          </p>
        </div>
      </div>
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Status
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Role
            </th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <TeamItem
            v-for="member in user.team.members"
            :key="member.id"
            :user="member"
            :delete-enabled="user.role === 'owner'"
            @delete-member="deleteTeamMember"
          />
          <TeamItem
            v-for="email in user.team.pendingInvites"
            :key="email"
            :user="email"
            :delete-enabled="user.role === 'owner'"
            @delete-member="deleteTeamMember"
            @resend-invite="inviteMember(email, true)"
          />
        </tbody>
      </table>
    </div>
    <div class="mt-1"></div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import { datadogLogs as log } from "@datadog/browser-logs";
import { Switch as SwitchVue, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { ArrowPathIcon } from "@heroicons/vue/20/solid";
import TeamItem from "../components/Home/TeamItem.vue";
import EnsureCloud from "../helpers/ensure-cloud";
import Spinner from "../components/Library/Spinner.vue";

export default defineComponent({
  components: {
    TeamItem,
    SwitchVue,
    SwitchGroup,
    SwitchLabel,
    ArrowPathIcon,
    Spinner,
  },
  data() {
    return {
      teamName: "",
      inviteLink: "",
      inviteEmail: "",
      teamNameRequired: false,
      inviteEmailError: "",
      autoJoin: false,
      replayIsTeamOnly: false,
      stripeIsLoading: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    subscriptionText() {
      if (this.user.team.plan === "free") return "Free Plan";
      if (this.user.team.plan === "pro") return "TestDriver Pro";
    },
    subscriptionColor() {
      if (this.user.team.plan === "free") return "text-gray-700";
      if (this.user.team.plan === "pro") return "text-lime-600";
    },
  },
  watch: {
    async autoJoin(newValue) {
      const updatedUser = await Cloud.teamAutoJoin({ autoJoin: newValue })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedUser) {
        this.$store.commit("setUser", updatedUser);
      }
    },

    async replayIsTeamOnly(newValue) {
      const updatedUser = await Cloud.teamReplayAccess({
        replayAccess: newValue ? "team" : "unlisted",
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedUser) {
        this.$store.commit("setUser", updatedUser);
      }
    },
  },
  mounted() {
    EnsureCloud();
  },
  created() {
    if (this.user) {
      this.teamName = this.user.team.name;
      this.autoJoin = this.user.team.autoJoin;
      this.replayIsTeamOnly =
        (this.user.team.replayAccess ?? "team") === "team";
      this.inviteLink = `${
        import.meta.env.VITE_APP_ENDPOINT || "https://app.testdriver.ai"
      }/invite?code=${this.user.team.invite.code}`;

      if (this.user.team.plan !== "free") {
        try {
          gtag("event", "conversion", {
            send_to: "AW-749090699/zLCOCIrO_K8aEIvvmOUC",
            value: 1.0,
            currency: "USD",
          });
        } catch (error) {
          log.logger.error("Error tracking gtag conversion", error);
        }
      }
    }
  },
  methods: {
    ...mapActions(["editTeamName"]),
    async teamProceed() {
      if (!this.teamName) {
        this.teamNameRequired = true;
      } else {
        this.teamNameRequired = false;
        await this.editTeamName(this.teamName);
        this.$toast.info("Team has been saved");
      }
    },
    async refreshApiKey() {
      const apiKey = await Cloud.refreshApiKey()
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });
      if (!apiKey) return;
      const user = await Cloud.whoami()
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });
      if (user) {
        this.$store.commit("setUser", user);
      }
    },
    copyInviteLink() {
      this.$copyText(this.inviteLink).then(
        () => {
          this.$toast.open({
            message: "Copied link to clipboard",
            type: "info",
          });

          log.logger.info("Copied Invite Link", {
            user: this.user,
          });
        },
        (e) => {
          log.logger.error(e);
          this.$toast.open({
            message: "There was a problem copying to clipboard",
            type: "error",
          });
        }
      );
    },
    async deleteTeamMember(user) {
      const deleteFunction = user.id
        ? Cloud.teamMemberDelete({ userId: user.id })
        : Cloud.teamPendingDelete({ email: user });

      const updatedUser = await deleteFunction
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedUser) {
        if (user.id) {
          this.$toast.info(`${user.profile.name} has been removed from team`);
        } else {
          this.$toast.info(`${user} has been removed from the pending invites`);
        }
        this.$store.commit("setUser", updatedUser);
      }
    },
    async inviteMember(email, onlySendEmail = false) {
      if (!email) {
        this.inviteEmailError = "Email is required";
        return;
      }

      if (
        // eslint-disable-next-line no-useless-escape
        !/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
          email
        )
      ) {
        this.inviteEmailError = "Email is invalid";
        return;
      }

      this.inviteEmailError = "";
      const updatedUser = await Cloud.teamInviteMember({
        email,
        onlySendEmail,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      this.$toast.success(`User ${email} was invited`);

      if (!onlySendEmail && updatedUser) {
        this.$store.commit("setUser", updatedUser);
        this.inviteEmail = "";
      }
    },
    manageSubscription() {
      Cloud.createPortalSession()
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
          this.$toast.error("Failed to create portal session");
        })
        .then((response) => {
          window.location.href = response.url;
        });
    },
    async subscribe() {
      this.stripeIsLoading = true;
      this.$toast.info("Redirecting to Stripe Checkout...");
      try {
        const response = await Cloud.createCheckoutSession({
          planId: "pro-monthly",
        })
          .headers({
            Authorization: `Bearer ${this.$store.state.token}`,
          })
          .tolerate((err) => {
            log.logger.error(err);
            console.log({ err });
            this.$toast.error("Failed to create checkout session");
            this.stripeIsLoading = false;
          });

        if (response?.url) {
          // Redirect to the Stripe Checkout
          window.location.href = response.url;
        }
      } catch (error) {
        log.logger.error("Error creating checkout session:", error);
        this.$toast.error("Failed to start subscription checkout");
      }
    },
  },
});
</script>
